import {
    IconButton,
} from "@mui/material";

import React from "react";

import { Link } from "react-router-dom"
import { IconEdit, IconTrash } from "@tabler/icons";
import { DISPONIBILIDAD_ROUTES, GENERAL_INFO_ROUTE, SPINS_ROUTE } from '../../config/routes';

import URL from './../../UI/general/url.svg'


const CardListBuilders = ({elements, editEvent, deleteEvent, builderSelectedSuccess, user}: any) => {
    console.log(user);
    
    return(
        <div className='builders-card-grid'>
            {
                elements.map((element: any, index: number) => 
                <div className='builders-cards' key={index}>
                    <div className='buiders-image-container'>
                        <img width={100} src={`https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${element.logo}`} alt="builders" className="builder-image" />
                    </div>
                    <div className='builders-info-container'>
                        <div className="project-name-edit-container">
                            <div className='project-name'>{element.name}</div>
                            {
                                user.role === 'UR0001' ?
                                <React.Fragment>
                                    <div className='project-divider'/>
                                    <div className='project-options-container'>
                                        <IconButton  onClick={() => editEvent(element)}>
                                            <IconEdit />
                                        </IconButton>
                                        <IconButton  onClick={() => deleteEvent(element)}>
                                            <IconTrash />
                                        </IconButton>
                                    </div>
                                </React.Fragment>
                                :null
                            }
                        </div>
                        <div className="project-url-see-container">
                            <div className="project-url-btn">
                                <img src={URL} alt=""/>
                                <div className="project-url-text" onClick = {() => {navigator.clipboard.writeText(element.url)}}>Copiar URL</div>
                            </div>
                            <Link to={user.role === 'UR0001' ? GENERAL_INFO_ROUTE+`/${element.id}`: user.name === 'MODELER' ? SPINS_ROUTE+`/${element.id}`: DISPONIBILIDAD_ROUTES+`/${element.id}`}>
                                <div className="project-see" onClick={() => {builderSelectedSuccess(element)}}>
                                    Ver
                                </div>
                            </Link>
                        </div>
                        
                        
                    </div>
                </div>
                )
            }
        </div>
    )
}

export default CardListBuilders