import React from "react";
import { connect } from "react-redux";
import {
  HashRouter,
  Route,
  Routes,
  Navigate,
  useParams,
} from "react-router-dom";

import {
  ADMINISTRATION_ROUTE,
  LOGIN_ROUTE,
  PROJECTS_ROUTE,
  GENERAL_INFO_ROUTE,
  CONTACT_INFO_ROUTE,
  GALLERIES_ROUTE,
  AMENITIES_ROUTE,
  PROPERTIES_ROUTE,
  LOCATION_ROUTE,
  POI_ROUTE,
  POI_CATEGORY_ROUTE,
  PROPERTIES_GENERAL_ROUTE,
  SPINS_ROUTE,
  UI_ROUTE,
  ASOLEACION_ROUTE,
  DISPONIBILIDAD_ROUTES,
  COTIZACION_ROUTES,
} from "./config/routes";

import LoadingComponent from "./components/generals/loading.component";
//import LoginComponent from "./components/login/";
import BuildersView from "./components/builders/";
import ProjectsView from ".//components/projects/";
import GeneralInformationView from "./components/generalInformation";
import AttributesViews from "./components/attributes";
import GalleryView from "./components/gallery";
import AmenitiesViews from "./components/amenities";
import PropertiesVies from "./components/properties";
import LocationView from "./components/location";
import POISViews from "./components/POI";
import GeneralPropertiesViews from "./components/generalProperties";
import SpinsViews from "./components/spins";
import UIViews from "./components/ui";
import POICategoryView from "./components/POICategory";
import AsoleacionView from "./components/asoleación";
import DisponibilidadViews from "./components/dispo";
import CotizacionView from "./components/cotización";
import ContactInformationView from "./components/contact";
import AlertUmbra from "./helpers/AlertUmbra";

import { closeAlert } from "./redux/actions";
import axios from "axios";
import { ApiConfig } from "./constants/defaultValues";

interface Props {
  stateLogin: any;
  message: string;
  type: string;
  show: boolean;
  closeAlert: any;
  user: any;
  params: any;
}

interface State {
  rol: any;
  id: any
}

function withParams(Component: any) {
  return (props: any) => <App {...props} params={useParams()} />;
}

class App extends React.Component<Props, State> {
  private interval: NodeJS.Timeout | null = null;
  constructor(props: Props) {
    super(props);
    const storedUser = localStorage.getItem("user");
    const currentURL = window.location.href;
    const segments = currentURL.split("/");
    this.state = {
      rol: storedUser ? JSON.parse(storedUser) : "",
      id: segments[segments.length - 1]
    };
    this.interval = null;
  }

  componentDidMount() {
    this.setupDataFetchInterval();
  }

  componentWillUnmount() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  }

  fetchData = () => {
    const projectOffline = localStorage.getItem("project_offline");
    axios
      .post(ApiConfig.URL2 + "project/download/offline", {
        project: projectOffline,
      })
      .then((response) => {
        localStorage.setItem("create_offline", response.data.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  checkLocalStorageAndFetchData = () => {
    const createOffline = localStorage.getItem("create_offline");
    if (createOffline === "creando") {
      this.fetchData();
    }
  };

  setupDataFetchInterval = () => {
    this.checkLocalStorageAndFetchData();
    this.interval = setInterval(() => {
      this.checkLocalStorageAndFetchData();
    }, 30000); // 30 segundos
  };

  render(): React.ReactNode {
    const { rol, id } = this.state;
    console.log(rol);
    

    return (
      <>
        <AlertUmbra
          variant={this.props.type}
          message={this.props.message}
          show={this.props.show}
          setShow={this.props.closeAlert}
        />
        <HashRouter>
          <Routes>
            <Route
              path={ADMINISTRATION_ROUTE}
              element={
                this.props.stateLogin ? (
                  <React.Suspense fallback={<LoadingComponent />}>
                    <BuildersView />
                  </React.Suspense>
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path={PROJECTS_ROUTE + "/:id"}
              element={
                this.props.stateLogin ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <ProjectsView />
                  </React.Suspense>
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            {/* <Route
              path={LOGIN_ROUTE}
              element={
                !this.props.stateLogin ? (
                  <React.Suspense fallback={<LoadingComponent />}>
                    <LoginComponent />
                  </React.Suspense>
                ) : (
                  <Navigate to={ADMINISTRATION_ROUTE} />
                )
              }
            /> */}
            <Route
              path={GENERAL_INFO_ROUTE + "/:id"}
              element={
                this.props.stateLogin && rol.role === "UR0001" ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <GeneralInformationView />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0002" ? (
                  <Navigate
                    to={DISPONIBILIDAD_ROUTES + "/" + id}
                  />
                ) : this.props.stateLogin && rol.role === "UR0003" ? (
                  <Navigate to={SPINS_ROUTE + "/" + id} />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path={CONTACT_INFO_ROUTE + "/:id"}
              element={
                this.props.stateLogin && rol.role === "UR0001" ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <ContactInformationView />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0002" ? (
                  <Navigate
                    to={DISPONIBILIDAD_ROUTES + "/" + id}
                  />
                ) : this.props.stateLogin && rol.role === "UR0003" ? (
                  <Navigate to={SPINS_ROUTE + "/" + id} />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path="/properties/attributes/:id"
              element={
                this.props.stateLogin && rol.role === "UR0001" ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <AttributesViews />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0002" ? (
                  <Navigate
                    to={DISPONIBILIDAD_ROUTES + "/" + id}
                  />
                ) : this.props.stateLogin && rol.role === "UR0003" ? (
                  <Navigate to={SPINS_ROUTE + "/" + id} />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path={PROPERTIES_ROUTE + "/:id"}
              element={
                this.props.stateLogin && rol.role === "UR0001" ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <PropertiesVies />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0002" ? (
                  <Navigate
                    to={DISPONIBILIDAD_ROUTES + "/" + id}
                  />
                ) : this.props.stateLogin && rol.role === "UR0003" ? (
                  <Navigate to={SPINS_ROUTE + "/" + id} />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path={GALLERIES_ROUTE + "/:id"}
              element={
                this.props.stateLogin && rol.role === "UR0001" ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <GalleryView />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0002" ? (
                  <Navigate
                    to={DISPONIBILIDAD_ROUTES + "/" + id}
                  />
                ) : this.props.stateLogin && rol.role === "UR0003" ? (
                  <Navigate to={SPINS_ROUTE + "/" + id} />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path={AMENITIES_ROUTE + "/:id"}
              element={
                this.props.stateLogin && rol.role === "UR0001" ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <AmenitiesViews />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0002" ? (
                  <Navigate
                    to={DISPONIBILIDAD_ROUTES + "/" + id}
                  />
                ) : this.props.stateLogin && rol.role === "UR0003" ? (
                  <Navigate to={SPINS_ROUTE + "/" + id} />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path={LOCATION_ROUTE + "/:id"}
              element={
                this.props.stateLogin && rol.role === "UR0001" ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <LocationView />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0002" ? (
                  <Navigate
                    to={DISPONIBILIDAD_ROUTES + "/" + id}
                  />
                ) : this.props.stateLogin && rol.role === "UR0003" ? (
                  <Navigate to={SPINS_ROUTE + "/" + id} />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path={POI_ROUTE + "/:id"}
              element={
                this.props.stateLogin && rol.role === "UR0001" ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <POISViews />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0002" ? (
                  <Navigate
                    to={DISPONIBILIDAD_ROUTES + "/" + id}
                  />
                ) : this.props.stateLogin && rol.role === "UR0003" ? (
                  <Navigate to={SPINS_ROUTE + "/" + id} />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path={POI_CATEGORY_ROUTE + "/:id/:poi"}
              element={
                this.props.stateLogin && rol.role === "UR0001" ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <POICategoryView />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0002" ? (
                  <Navigate
                    to={DISPONIBILIDAD_ROUTES + "/" + id}
                  />
                ) : this.props.stateLogin && rol.role === "UR0003" ? (
                  <Navigate to={SPINS_ROUTE + "/" + id} />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path={PROPERTIES_GENERAL_ROUTE + "/:id"}
              element={
                this.props.stateLogin && rol.role === "UR0001" ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <GeneralPropertiesViews />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0002" ? (
                  <Navigate
                    to={DISPONIBILIDAD_ROUTES + "/" + id}
                  />
                ) : this.props.stateLogin && rol.role === "UR0003" ? (
                  <Navigate to={SPINS_ROUTE + "/" + id} />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path={SPINS_ROUTE + "/:id"}
              element={
                this.props.stateLogin &&
                (rol.role !== "CLIENT" || rol.role === "UR0003") ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <SpinsViews />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0002" ? (
                  <Navigate
                    to={DISPONIBILIDAD_ROUTES + "/" + id}
                  />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path={UI_ROUTE + "/:id"}
              element={
                this.props.stateLogin && rol.role === "UR0001" ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <UIViews />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0002" ? (
                  <Navigate
                    to={DISPONIBILIDAD_ROUTES + "/" + id}
                  />
                ) : this.props.stateLogin && rol.role === "UR0003" ? (
                  <Navigate to={SPINS_ROUTE + "/" + id} />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path={ASOLEACION_ROUTE + "/:id"}
              element={
                this.props.stateLogin && rol.role === "UR0001" ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <AsoleacionView />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0002" ? (
                  <Navigate
                    to={DISPONIBILIDAD_ROUTES + "/" + id}
                  />
                ) : this.props.stateLogin && rol.role === "UR0003" ? (
                  <Navigate to={SPINS_ROUTE + "/" + id} />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path={DISPONIBILIDAD_ROUTES + "/:id"}
              element={
                this.props.stateLogin &&
                (rol.role === "UR0001" || rol.role === "UR0002") ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <DisponibilidadViews />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0003" ? (
                  <Navigate to={SPINS_ROUTE + "/" + id} />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
            <Route
              path={COTIZACION_ROUTES + "/:id"}
              element={
                this.props.stateLogin && rol.role === "UR0001" ? (
                  <React.Suspense fallback={<div className="loading" />}>
                    <CotizacionView />
                  </React.Suspense>
                ) : this.props.stateLogin && rol.role === "UR0002" ? (
                  <Navigate
                    to={DISPONIBILIDAD_ROUTES + "/" + id}
                  />
                ) : this.props.stateLogin && rol.role === "UR0003" ? (
                  <Navigate to={SPINS_ROUTE + "/" + id} />
                ) : (
                  <Navigate to={LOGIN_ROUTE} />
                )
              }
            />
          </Routes>
        </HashRouter>
      </>
    );
  }
}

const mapStateToProps = ({ authReducer, generalReducer }: any) => {
  const { user, stateLogin, loading } = authReducer;
  const { message, type, show } = generalReducer;
  return { user, stateLogin, loading, message, type, show };
};

export default connect(mapStateToProps, {
  closeAlert,
})(withParams(App));
