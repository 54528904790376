module.exports = {
    sidebarData: () => {
        return[
            {
                "name":"Información General",
                "linkTo":`/general-information/`,
                "subMenu":[],
                "rol":['UR0001']
            },
            {
                "name":"Información Contacto",
                "linkTo":`/contact/`,
                "subMenu":[],
                "rol":['UR0001']
            },
            {
                "name":"Inmuebles",
                "linkTo":`/general-properties/`,
                "subMenu":[
                    {
                        "name":"Atributos",
                        "linkTo":`/properties/attributes/`,
                        "rol":['UR0001']
                    },
                    {
                        "name":"Inmuebles",
                        "linkTo":`/properties/`,
                        "rol":['UR0001']
                    }
                ],
                "rol":['UR0001']
            },
            {
                "name":"Ubicación: vías",
                "linkTo":`/location/`,
                "subMenu":[],
                "rol":['UR0001']
            },
            {
                "name":"Ubicación: Puntos de interés",
                "linkTo":`/poi/`,
                "subMenu":[],
                "rol":['UR0001']
            },
            {
                "name":"Amenidades",
                "linkTo":`/amenities/`,
                "subMenu":[],
                "rol":['UR0001']
            },
            {
                "name":"Galería",
                "linkTo":`/galleries/`,
                "subMenu":[],
                "rol":['UR0001']
            },
            {
                "name":"Biblioteca de Spin",
                "linkTo":`/spins/`,
                "subMenu":[],
                "rol":['UR0001']
            },
            {
                "name":"Módulos",
                "linkTo":``,
                "subMenu":[
                    {
                        "name":"Disponibilidad",
                        "linkTo":"/modulos/disponibilidad/",
                        "rol":['UR0001', 'UR0002']
                    },
                    {
                        "name":"Cotización",
                        "linkTo":"/modulos/cotizacion/",
                        "rol":['UR0001']
                    },
                    {
                        "name":"Asoleación",
                        "linkTo":"/modulos/asoleacion/",
                        "rol":['UR0001']
                    }/*,
                    {
                        "name":"Avance de obra",
                        "linkTo":"/modulos/avances/"
                    }*/
                ],
                "rol":['UR0001', 'UR0002']
            }
        ]
    }
}