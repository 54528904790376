interface Projects {
    data: any,
    title: string,
    event: any,
    user: any
}

const ProjectsHeader = ({data,title, event, user}: Projects) => {
    return(
        <div className='header-builder-container'>
            <div className='builder-title'>
                {data.selectedBuilder.name}
            </div>
            <div className="breadcrumbs-container">
                <div><a href="/" className="breadcrumbs-builders">/ Constructoras </a>/ Proyectos</div>
            </div>
            {
                user.role === 'UR0001' ?
                <div className='new-builders-btn' onClick={() => {event(true)}}>
                    Nuevo proyecto
                </div>:null
            }
        </div>
    )
}

export  default ProjectsHeader