import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import Select from "react-select";
import CustomSelectInput from "../../helpers/CustomSelectInput";

const HeaderDispo = ({
  attributesList,
  handleActivateDispo,
  handleActiveDispo,
  user,
  dispoObject,
  handleChangeDispoFilter,
  handleChangeAttributeFilter,
  selectedOptionsSeconds,
  project
}: any) => {
  const [activeDisponible, setActiveDisponible] = useState(true)
  const [activeVendido, setActiveVendido] = useState(true)
  const [activeReservado, setActiveReservado] = useState(true)
  const [activeNoDisponible, setActiveNoDisponible] = useState(true)

  const formatSelectPrimary = (data: any) => {
    let dataOpciones: any = [];
    if (data.length > 0) {
      data.forEach((element: any) => {
        dataOpciones.push({
          value: element.value,
          label: element.value,
        });
      });
    }
    return dataOpciones;
  };

  useEffect(() => {
    setActiveDisponible(project.state_disponible)
    setActiveVendido(project.state_vendido)
    setActiveReservado(project.state_reservado)
    setActiveNoDisponible(project.state_no_disponible)
  }, [project])

  const sendActiveDisponible = (e: any) => {
    setActiveDisponible(!e)
    handleActiveDispo({
      disponible: e,
      vendido: activeVendido,
      reservado: activeReservado,
      no_disponible: activeNoDisponible
    })
  }

  const sendActiveVendido = (e: any) => {
    setActiveVendido(e)
    handleActiveDispo({
      disponible: activeDisponible,
      vendido: e,
      reservado: activeReservado,
      no_disponible: activeNoDisponible
    })
  }

  const sendActiveReservado = (e: any) => {
    setActiveReservado(e)
    handleActiveDispo({
      disponible: activeDisponible,
      vendido: activeVendido,
      reservado: e,
      no_disponible: activeNoDisponible
    })
  }

  const sendActiveNoDisponible = (e: any) => {
    setActiveNoDisponible(e)
    handleActiveDispo({
      disponible: activeDisponible,
      vendido: activeVendido,
      reservado: activeReservado,
      no_disponible: e
    })
  }
  

  return (
    <div className="header-dispo-container">
      <div className="header-dispo-title">Módulo de Disponibilidad</div>
      {/* {user.name === "ADMIN" ? ( */}
      <div className="pricing-btn-filter-container">
        {attributesList.map((attribute: any, index: number) =>
          attribute.type === "Hierarchical" ? (
            <Select
              components={{
                Input: CustomSelectInput,
              }}
              className="react-select"
              classNamePrefix="react-select"
              isMulti
              name="form-field-name"
              value={selectedOptionsSeconds[attribute.name]}
              onChange={(e) => handleChangeAttributeFilter(e, attribute.name)}
              options={formatSelectPrimary(attribute.values)}
            />
          ) : null
        )}
      </div>

      <div className="activate-dispo-states-container">
        <div className="activate-dispo-states-individual-container">
          <div className="activate-dispo-state-name">Disponible</div>
          <Switch
            onChange={() => {
              handleChangeDispoFilter("DISPONIBLE");
            }}
            checked={dispoObject["DISPONIBLE"] === 1}
            className="switch-state"
            onColor={"#97EBA2"}
            onHandleColor={"#5AE26C"}
            uncheckedIcon={false}
            checkedIcon={false}
            height={18}
            width={48}
          />
        </div>
        <div className="activate-dispo-states-individual-container">
          <div className="activate-dispo-state-name">Vendido</div>
          <Switch
            onChange={() => {
              handleChangeDispoFilter("VENDIDO");
            }}
            checked={dispoObject["VENDIDO"] === 1}
            className="switch-state"
            onColor={"#EB9797"}
            onHandleColor={"#EE3F3F"}
            uncheckedIcon={false}
            checkedIcon={false}
            height={18}
            width={48}
          />
        </div>
        <div className="activate-dispo-states-individual-container">
          <div className="activate-dispo-state-name">Reservado</div>
          <Switch
            onChange={() => {
              handleChangeDispoFilter("RESERVADO");
            }}
            checked={dispoObject["RESERVADO"] === 1}
            className="switch-state"
            onColor={"#FFAA28"}
            onHandleColor={"#FFBF4F"}
            uncheckedIcon={false}
            checkedIcon={false}
            height={18}
            width={48}
          />
        </div>
        <div className="activate-dispo-states-individual-container">
          <div className="activate-dispo-state-name">No Disponible</div>
          <Switch
            onChange={() => {
              handleChangeDispoFilter("NO DISPONIBLE");
            }}
            checked={dispoObject["NO DISPONIBLE"] === 1}
            className="switch-state"
            onColor={"#A6A6A6"}
            onHandleColor={"#434343"}
            uncheckedIcon={false}
            checkedIcon={false}
            height={18}
            width={48}
          />
        </div>
      </div>
      {/* ) : null} */}
      {/* {user.name === "ADMIN" ? (
        <div className="activate-dispo-container">
          <div className="activate-dispo-title">Activar disponibilidad</div>
          <Switch
            onChange={() => {
              setActivateDispo(!activateDispo);
              handleActivateDispo(!activateDispo);
            }}
            checked={activateDispo}
            className="activate-dispo-switch"
            onColor={"#FFCCE4"}
            onHandleColor={"#ED0874"}
            uncheckedIcon={false}
            checkedIcon={false}
            height={18}
            width={48}
          />
        </div>
      ) : null} */}
      {user.name === "UR0001" ? <div className="activate-dispo-in-masterplan-states-container">
        <div className="activate-dispo-masterplan-states-individual-container">
          <label htmlFor="disponible">Disponible</label>
          <input type="checkbox" checked={activeDisponible}  onChange={() => sendActiveDisponible(!activeDisponible)} name="disponible" id="" />
        </div>
        <div className="activate-dispo-masterplan-states-individual-container">
          
        <label htmlFor="Vendido">Vendido</label>
          <input type="checkbox" checked={activeVendido}  onChange={() => sendActiveVendido(!activeVendido)} name="Vendido" id="" />
        </div>
        <div className="activate-dispo-masterplan-states-individual-container">
          
        <label htmlFor="Reservado">Reservado</label>
          <input type="checkbox" checked={activeReservado}  onChange={() => sendActiveReservado(!activeReservado)} name="Reservado" id="" />
        </div>
        <div className="activate-dispo-masterplan-states-individual-container">
          
        <label htmlFor="NoDisponible">No Disponible</label>
          <input type="checkbox" checked={activeNoDisponible}  onChange={() => sendActiveNoDisponible(!activeNoDisponible)} name="NoDisponible" id="" />
        </div>
      </div>: null}
    </div> 
  );
};

export default HeaderDispo;
